export enum AppRoutes {
  HOME_ROUTE = '/',
  HOW_IT_WORK = '/how-it-works',
  OUR_STORY = '/our-story',
  ORDER_STEP_ONE = '/order/step-1',
  ORDER_STEP_TWO = '/order/step-2',
  ORDER_STEP_THREE = '/order/step-3',
  ORDER_STEP_FOUR = '/order/step-4',
  ORDER_STEP_FIVE = '/order/step-5',
  LOGIN = '/login',
  SET_PASSWORD = '/set-password',
  OUR_MENU = '/our-menu',
  MY_ACCOUNT = '/my-account',
  SUCCESS = '/success',
  FAQS = '/faqs',
  FORGOT_PASSWORD = '/forgot-password',
  TERMS = '/terms-and-conditions',
  PRIVACY = '/privacy-policy',
  REFUNDS = '/refund-policy',
  ORDERS = '/my-account/orders',
  SUBSCRIPTIONS = '/my-account/subscriptions',
  REFERRALS = '/my-account/referrals',
  ACTIVATE_ACCOUNT = '/activate-account',
  REVIEWS = '/reviews',
  CONTACT_US = '/contact-us',
  GIFT = '/gifts',
  GIFT_BOX = '/gift-box',
  GIFT_BOX_STEP_ONE = '/gift-box/step-1',
  GIFT_BOX_STEP_TWO = '/gift-box/step-2',
  GIFT_BOX_STEP_THREE = '/gift-box/step-3',
  GIFT_CARD = '/gift-card',
  GIFT_CARD_STEP_ONE = '/gift-card/step-1',
  GIFT_CARD_STEP_TWO = '/gift-card/step-2',
  GIFT_CARD_USA_STEP_ONE = '/usa/gift-card/step-1',
  DIWALI_GIFT = '/diwali/step-1',
  DIWALI_GIFT_STEP_TWO = '/diwali/step-2',
  VEGAN_GIFT = '/vegan-gift',
  INDIA_FOOD_GIFT = '/intro-to-indian-food-gift',
  BESTSELLERS_GIFT = '/bestsellers-gift',
  NEW_US_GIFT_BOX = `/redirect?id=eyJpZCI6NywidHlwZSI6IkdJRlRCT1gifQ==`,
  BLOG = '/blog',
  CREDITS = '/my-account/credits',
  PRESS_KIT = '/press-kit',
  ACCESSIBILITY = '/accessibility',
}
